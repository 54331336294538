<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row align="center" justify="end">
      <v-col cols="auto" align="center">
        <v-btn-toggle v-model="toggle_exclusive">
          <v-btn :value="1" text @click="showData(1)">日</v-btn>
          <v-btn :value="2" text @click="showData(2)">週</v-btn>
          <v-btn :value="3" text @click="showData(3)">月</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="2" align="center">
        <v-select :items="items" label="" />
      </v-col>
    </v-row>
    <v-row>
      <!-- vue-chartist 単体で利用 -->
      <v-col cols="12">
        <chartist
          :data="testChart.data"
          :options="testChart.options"
          :responsive-options="testChart.responsiveOptions"
          type="Bar"
          class="test-graph"
          style="max-height: 450px"
        />
      </v-col>
    </v-row>

    <!--  -->
    <v-row align="center" class="mt-8">
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今日</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥1,234,567</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今週</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥1,234,567</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" align="center">
        <v-row>
          <v-col align="center" class="pa-0">
            <span class="text-h4">今月</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <span class="text-h2">￥1,234,567</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// tooltip
// https://github.com/beepnl/beep-vue-app/blob/403665a5d8b8da13544b139e3fe2a2f41e23c126/src/router/views/flashlog.vue
// https://github.com/tmmdata/chartist-plugin-tooltip
// https://github.com/Yopadd/vue-chartist/issues/32
import "chartist/dist/chartist.min.css";
import "chartist-plugin-tooltips-updated";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";

const DATAS = {
  // 日
  data1: {
    labels: [
      "8/1",
      "8/2",
      "8/3",
      "8/4",
      "8/5",
      "8/6",
      "8/7",
      "8/8",
      "8/9",
      "8/10",
      "8/11",
      "8/12",
      "8/13",
      "8/14",
    ],
    series: [
      [
        { meta: "後払い", value: 7000 },
        { meta: "後払い", value: 10000 },
        { meta: "後払い", value: 6000 },
        { meta: "後払い", value: 7000 },
        { meta: "後払い", value: 7000 },
        { meta: "後払い", value: 7000 },
        { meta: "後払い", value: 7000 },
        { meta: "先払い", value: 1000 },
        { meta: "先払い", value: 2000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 1000 },
      ],
      [
        { meta: "先払い", value: 1000 },
        { meta: "先払い", value: 2000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 1000 },
        { meta: "先払い", value: 1000 },
        { meta: "先払い", value: 2000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 3000 },
        { meta: "先払い", value: 4000 },
        { meta: "先払い", value: 1000 },
      ],
    ],
  },
  // 週
  data2: {
    labels: [
      "1日週",
      "8日週",
      "15日週",
      "22日週",
      "29日週",
      "1日週",
      "8日週",
      "15日週",
      "22日週",
      "29日週",
    ],
    series: [
      [
        100000, 120000, 80000, 90000, 40000, 100000, 120000, 80000, 90000,
        40000,
      ],
      [70000, 100000, 80000, 60000, 80000, 100000, 120000, 80000, 90000, 40000],
    ],
  },
  // 月
  data3: {
    labels: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    series: [
      [
        3000000, 1500000, 1000000, 800000, 900000, 3000000, 1500000, 1000000,
        800000, 900000, 800000, 900000,
      ],
      [
        1000000, 1400000, 900000, 900000, 500000, 3000000, 1500000, 1000000,
        800000, 900000, 800000, 900000,
      ],
    ],
  },
};

export default {
  // TODO
  // - [x] グラフ切替 (日/週/月)
  // - [x] 色分け
  // - [x] axisX カスタマイズ
  // - [x] axisY カスタマイズ
  // - [x] tooltip
  data() {
    return {
      toggle_exclusive: 1,
      items: ["過去14日間", "2022/7", "2022/6", "2022/5"],
      chart: {
        data: {},
      },
      testChart: {
        data: {},

        options: {
          plugins: [this.$chartist.plugins.tooltip()],
          axisX: {
            showGrid: true,
            labelInterpolationFnc: function (value) {
              return value;
            },
          },
          axisY: {
            offset: 80, // margin-left (px)
            scaleMinSpace: 30, // 指定高さ(px) で数値は auto scale
            labelInterpolationFnc: function (value) {
              return value;
              // return value + " 円 ";
              // return value / 10000 + " 万";
            },
          },
          stackBars: true,
          low: 0,
          // high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          // [
          //   "screen and (max-width: 640px)",
          //   {
          //     seriesBarDistance: 5,
          //     axisX: {
          //       labelInterpolationFnc: function (value) {
          //         return value[0];
          //       },
          //     },
          //   },
          // ],
        ],
        // events: [
        //   {
        //     event: "draw",
        //     fn: (context) => {
        //       context.element.attr({
        //         style: `stroke: hsl(${Math.floor(
        //           (this.$chartist.getMultiValue(context.value) / 100) * 100
        //         )}, 60%, 50%);`,
        //       });
        //     },
        //   },
        // ],
      },
    };
  },
  mounted() {
    this.testChart.data = DATAS.data1;
  },
  methods: {
    showData(index) {
      if (index === 1) {
        this.testChart.data = DATAS.data1;
      } else if (index === 2) {
        this.testChart.data = DATAS.data2;
      } else if (index === 3) {
        this.testChart.data = DATAS.data3;
      }
    },
  },
};
</script>

<style>
.test-graph .ct-bar {
  stroke-width: 40px;
}
.test-graph .ct-series-a .ct-bar {
  stroke: #12bfae;
}
.test-graph .ct-series-b .ct-bar {
  stroke: #ffd05b;
}
.test-graph .ct-label {
  font-size: 16px;
  /* color: red; */
}
</style>
